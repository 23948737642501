import React from 'react';
import '../styles/Experiences.css';

const educations = [
  {
    date: 'July 2023 — PRESENT',
    degree: 'Bachelors of Software Engineering',
    institution: 'Curtin University Malaysia',
    description: `Specialized in software engineering with coursework in algorithms, 
                  data structures, system design, and project management.`,
    achievements: ['Head of Website Team for IEEE Club', 'Vice President of Google Developer Student Club (GDSC)', 'Mobile App Development Helper for IEEE Club',
      'Computing Nation Programming Competition (3rd Place)', 'Computing Nation Networking Competition (Participant)'],
  },
  {
    date: 'Oct 2022 — June 2023',
    degree: 'Foundation in Science and Engineering',
    institution: 'Curtin University Malaysia',
    description: `Focused on foundational engineering skills such as programming, engineering mathematics, and 
                  leadership. Gained practical experience through group projects.`,
    achievements: ['Secretary for Curtin Strikers Bowling Club'],
  },
];

const experiences = [
  {
    date: 'March 2025 — Present',
    role: 'Programming Teacher (Part-time)',
    company: 'Realfun Academy Sdn. Bhd.',
    description: `Teaching programming languages such as Scratch, HTML, CSS, JavaScript, and Python to students`,
    companyUrl: 'https://realfun.my/',
    links: [],
    technologies: ['Scratch', 'Python', 'HTML', 'CSS', 'JavaScript'],
  },
  {
    date: 'Dec 2024 — Feb 2025',
    role: 'Web Developer (Internship)',
    company: 'MediaPlus Digital Sdn. Bhd.',
    description: `Developed and styled front-end interactive websites for MediaPlus Digital Sdn. Bhd. clients, 
                  including Corporate Website, E-commerce Website, Landing Page, and Website Maintenance.`,
    companyUrl: 'https://mediaplus.com.sg/',
    links: [
      { name: 'Storage Centre Pte Ltd', url: 'https://storagecentre.com.sg/' },
      { name: 'Yih Hwa Enterprise (S) Pte Ltd', url: 'https://yihhwa.com/' },
    ],
    technologies: ['HTML', 'CSS', 'WordPress', 'Elementor & Flatsome Theme'],
  },
  {
    date: 'Dec 2024 — Feb 2025',
    role: 'System Design and Development (Remote Internship)',
    company: 'Realfun Academy Sdn. Bhd.',
    description: `Developed and styled interactive web app systems for Realfun Academy Sdn. Bhd., 
                  including the inventory management system with the use of Gemini API to detect items from images
                  and NASA Space Apps Challenge Sarawak Roadshow Scheduling System.`,
    companyUrl: 'https://realfun.my/',
    links: [
      { name: 'Scheduling System', url: 'https://scheduling-system-iota.vercel.app/mainpage' },
      { name: 'Iventory Management System', url: 'https://inventory-management-system-eosin.vercel.app/dashboard' },
    ],
    technologies: ['HTML', 'CSS', 'JavaScript', 'React', 'Firebase', 'Gemini API'],
  },
];

const Experiences = () => {
  return (
    <div className="experiences">
      <div className="experiences-width">
        {/* Education Section */}
        <div className="education-section">
          <h2>Education</h2>
          {educations.map((edu, index) => (
            <div className="education" key={index}>
              <div className="date">{edu.date}</div>
              <div className="details">
                <h3>
                  {edu.degree} <span>· {edu.institution}</span>
                </h3>
                <p>{edu.description}</p>
                {edu.achievements.length > 0 && (
                  <ul className="achievements">
                    {edu.achievements.map((achievement, idx) => (
                      <li key={idx}>{achievement}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Work Experiences Section */}
        <div className="experience-section">
          <h2>Work Experiences</h2>
          {experiences.map((exp, index) => (
            <a
              href={exp.companyUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="experience-link"
              key={index}
            >
              <div className="experience">
                <div className="date">{exp.date}</div>
                <div className="details">
                  <h3>
                    {exp.role} <span>· {exp.company}</span>
                    <span className="material-symbols-outlined experience-icon">arrow_outward</span>
                  </h3>
                  <p>{exp.description}</p>
                  {exp.links.length > 0 && (
                    <div className="links" onClick={(e) => e.stopPropagation()}>
                      {exp.links.map((link, idx) => (
                        <a
                          href={link.url}
                          key={idx}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <span className="material-symbols-outlined link-icon">link</span> {link.name}
                        </a>
                      ))}
                    </div>
                  )}
                  <div className="technologies">
                    {exp.technologies.map((tech, idx) => (
                      <span className="tech" key={idx}>
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Experiences;
